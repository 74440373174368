// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archives-js": () => import("./../../../src/pages/archives.js" /* webpackChunkName: "component---src-pages-archives-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jeopardy-js": () => import("./../../../src/pages/jeopardy.js" /* webpackChunkName: "component---src-pages-jeopardy-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-quadblocks-js": () => import("./../../../src/pages/quadblocks.js" /* webpackChunkName: "component---src-pages-quadblocks-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sbom-js": () => import("./../../../src/pages/sbom.js" /* webpackChunkName: "component---src-pages-sbom-js" */),
  "component---src-pages-upcoming-js": () => import("./../../../src/pages/upcoming.js" /* webpackChunkName: "component---src-pages-upcoming-js" */)
}

